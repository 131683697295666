
import * as __star__ from '@clayui/data-provider';

const {
default: __default__,
FetchPolicy,
NetworkStatus,
Sorting,
useResource,
__esModule
} = __star__;

export {
FetchPolicy,
NetworkStatus,
Sorting,
useResource,
__esModule
};
export default __default__;
